/* License Types */

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const LICENSE_TYPES = {
  TEAM: 'Digital Team',
  CLEARBIT: 'Lead Data Enrichment',
  API_AUTO: 'Automation And CRM',
  CUSTOM_DIGI: 'Custom digital package'
};
export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  NO_MEDIA: 'NO_MEDIA'
};

export const GENERATE_CARDS_LIMIT = 5000;

export const QR_TYPES = [
  {
    title: 'Center Qr',
    type: 'center-logo'
  },
  {
    title: 'Background Qr',
    type: 'background'
  },
  {
    title: 'Round Qr',
    type: 'round'
  },
  {
    title: 'Legacy Qr', //Only qr title is changed from standard to legacy to show in UI value is same(25/04/22).
    type: 'standard'
  }
];

export const CARD_TYPES = {
  MOBILO_CARD: {
    id: 'MC',
    db_value: 'MOBILO_CARD',
    name: 'Mobilo Card'
  },
  MOBILO_CARD_CUSTOM: {
    id: 'MCC',
    db_value: 'MOBILO_CARD_CUSTOM',
    name: 'Mobilo Card (custom)'
  },
  ENTERPRISE_SERVICE: {
    id: 'ES',
    db_value: 'ENTERPRISE_SERVICE',
    name: 'Enterprise Service'
  },
  MOBILO_METAL_PROMO: {
    id: 'MMP',
    db_value: 'MOBILO_METAL_PROMO',
    name: 'Mobilo Metal (promo)'
  },
  MOBILO_METAL: {
    id: 'MM',
    db_value: 'MOBILO_METAL',
    name: 'Mobilo Metal'
  },
  MOBILO_WOOD_PROMO: {
    id: 'MWP',
    db_value: 'MOBILO_WOOD_PROMO',
    name: 'Mobilo Wood (promo)'
  },
  MOBILO_WOOD: {
    id: 'MW',
    db_value: 'MOBILO_WOOD',
    name: 'Mobilo Wood'
  },
  FREE_MOBILO_CARD: {
    id: 'FMC',
    db_value: 'FREE_MOBILO_CARD',
    name: 'Free Mobilo Card'
  }
};

export const CARD_TYPE_NAME = [
  'Mobilo Card',
  'Mobilo Card (custom)',
  'Enterprise Service',
  'Mobilo Metal (promo)',
  'Mobilo Metal',
  'Mobilo Wood',
  'Mobilo Wood (promo)',
  'Free Mobilo Card',
  'Sample Pack Card'
];
export const NEW_COLLECTION_DATA_TYPE = [
  {
    name: 'Card only',
    type: 'card_only'
  },
  {
    name: '+ Key Fob',
    type: 'key_fob'
  },
  {
    name: '+ Smart Button',
    type: 'smart_button'
  },
  {
    name: '+ Key Fob + Smart Button',
    type: 'key_fob_smart_button'
  }
];
export const LIMIT_PRIMARY = 10;
export const LIMIT_SECONDARY = 12;

export const PRODUCT_TYPE_IDENTIFIER_OPT = [
  {
    name: 'CUSTOM DESIGN',
    value: 'CUSTOM_DESIGN'
  },
  {
    name: 'MOBILO DESIGN',
    value: 'MOBILO_DESIGN'
  },
  {
    name: 'CUSTOM DESIGN',
    value: 'CUSTOM_DESIGN'
  },
  {
    name: 'MOBILO PLASTIC',
    value: 'MOBILO_PLASTIC'
  },
  {
    name: 'MOBILO WOOD',
    value: 'MOBILO_WOOD'
  },
  {
    name: 'MOBILO METAL',
    value: 'MOBILO_METAL'
  },
  {
    name: 'DIGITAL TEAM',
    value: 'DIGITAL_TEAM'
  },
  {
    name: 'DIGITAL TEAM + CARD',
    value: 'DIGITAL_TEAM_+_CARD'
  },
  {
    name: 'AUTOMATION AND CRM',
    value: 'AUTOMATION_AND_CRM'
  },
  {
    name: 'AUTOMATION AND CRM MONTHLY',
    value: 'AUTOMATION_AND_CRM_MONTHLY'
  },
  {
    name: 'AUTOMATION AND CRM YEARLY',
    value: 'AUTOMATION_AND_CRM_YEARLY'
  },
  {
    name: 'LEAD DATA ENRICHMENT',
    value: 'LEAD_DATA_ENRICHMENT'
  },
  {
    name: 'LEAD DATA ENRICHMENT MONTHLY',
    value: 'LEAD_DATA_ENRICHMENT_MONTHLY'
  },
  {
    name: 'LEAD DATA ENRICHMENT YEARLY',
    value: 'LEAD_DATA_ENRICHMENT_YEARLY'
  },
  {
    name: 'CUSTOM DIGITAL PACKAGE',
    value: 'CUSTOM_DIGITAL_PACKAGE'
  },
  {
    name: 'CUSTOM TEAM MOBILO PLASTIC',
    value: 'CUSTOM_TEAM_MOBILO_PLASTIC'
  },
  {
    name: 'CUSTOM TEAM MOBILO WOOD',
    value: 'CUSTOM_TEAM_MOBILO_WOOD'
  },
  {
    name: 'CUSTOM TEAM MOBILO METAL',
    value: 'CUSTOM_TEAM_MOBILO_METAL'
  }
];

export const PRODUCT_ACTIONS = [
  {
    action: 'Mobilo Card'
  },
  {
    action: 'Mobilo Card (custom)'
  },
  {
    action: 'Mobilo Wood'
  },
  {
    action: 'Mobilo Metal'
  },
  {
    action: 'Mobilo Wood'
  },
  {
    action: 'Free Mobilo Card'
  },
  {
    action: 'Sample Pack Card'
  },
  {
    action: 'TEAM'
  },
  {
    action: 'CLEARBIT'
  },
  {
    action: 'API_AUTO'
  },
  {
    action: 'CUSTOM_DIGI'
  }
];

export const COUNTRIES = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'land Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  {
    name: 'Cote DIvoire',
    code: 'CI'
  },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea (Democratic Peoples Republic of)', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Barthélemy', code: 'BL' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Martin (French part)', code: 'MF' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Sint Maarten (Dutch part)', code: 'SX' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'South Sudan', code: 'SS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];
export const SOCIAL_PAGE_TEMPLATE_URL =
  'https://firebasestorage.googleapis.com/v0/b/mobilo-dev-1f409.appspot.com/o/statics_folder%2Ftemplate.handlebars?alt=media&token=a156abd8-df64-409f-a44f-7533cdb38f74';
export const LEAD_GEN_TEMPLATE_URL =
  'https://firebasestorage.googleapis.com/v0/b/mobilo-dev-1f409.appspot.com/o/statics_folder%2Flead_gen_template_handlebar.zip?alt=media&token=d86dc9a4-6b4a-40a9-9c19-df5fa45b66e5';

export const DEFAULT_NEWS_CATEGORY = [
  'Blog',
  'Important',
  'Updates',
  'New Feature'
];
export const ADMIN_DEPT_ID = 'orgAdmin';

export const NOTE_MESSAGE = `Thanks for providing your design instructions. Love the proof? Go ahead and tap 'Approve Design'. To make changes, please leave your feedback below.`;

export const EDITABLE_STATE = {
  DESIGN_IN_PROGRESS: false,
  NEED_DESIGN_APPROVAL: false,
  DESIGN_APPROVED: true
};
export const REGEX = {
  NAME: /^[A-zÀ-ý!@#\$%\^\&*\s\)\(+=._-]+$/g, //It will accept accented letters in a string
  ZIPCODE: /^[A-Z0-9a-z _]*$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^[a-zA-Z0-9\d@$!%*#?&]{8,}$/,
  PHONE: /^[0-9.-]*$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  LETTERS: /^[a-zA-Z]+$/
};
export const ACTION_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete'
};
export const RELEASE_WARNING =
  'WARNING: This action cannot be reversed. This card will be removed from all accounts, licenses, and organizations. Any user will need to sign up again after tapping the card. Confirm?';

export const RESOLUTION_FACTOR = 5;
export const DEFAULT_FONT_SIZE = 14;
export const BRANDED_LINK_TYPE = 'CNAME';
export const EMAIL_ONBOARDING_TYPE = [
  {
    name: 'Pre-Shipping',
    type: 'preShipping'
  },
  {
    name: 'Post-Shipping',
    type: 'postShipping'
  }
];
export const CARDTYPE_FOR_THUMBNAIL = [
  'Mobilo Card (custom)',
  'Mobilo Metal (promo)',
  'Mobilo Metal',
  'Mobilo Hybrid Metal',
  'Mobilo Wood (promo)',
  'Mobilo Wood'
];

export const STATUS_DEFINATION = {
  PENDING: 'pdf creation not yet started',
  IN_PROGRESS: 'pdf creation is running',
  READY: 'pdf is created, ready for printing'
};

export const METAL_VENDOR = 'METAL';
export const MAT = 'MAT';
export const ALIGNMENT = ['LEFT', 'CENTER', 'RIGHT'];

export const LEGACY_DESIGN_EXPERIENCE = 'legacyDesignExperience';
export const DESIGN_CENTRE = 'designCenter';

export const CARD_WIDTH = 405;
export const CARD_HALF_WIDTH = 202.5;

export const ERROR_MESSAGES = {
  EMAIL_INVALID: 'Card generation process failed due to invalid email found.',
  AI_CONVERSION_FAILED:
    'AI Conversion process failed, click on retry button to continue.',
  CARD_GENERATION_FAILED:
    'Card generation process failed, click on retry button to continue.',
  UNKNOWN_CARD:
    'Card generation process failed, click on retry button to continue.',
  UNKNOWN_AI:
    'AI Conversion process failed, click on retry button to continue.',
  DESIGN_SET_NOT_FOUND:
    'Card generation process failed due to a design set not found.',
  OWNER_NOT_EXIST: 'Card generation process failed due to the owner not found.',
  DESIGN_NOT_APPROVED:
    'Card generation process failed due to design not approved yet.',
  FAULTY_USER_FOUND: 'Card generation process failed due to faulty user found.',
  CARDS_NOT_CREATED:
    'Cards are not created, click on retry button to continue.',
  FEW_CARDS_NOT_CREATED:
    'Few cards are not created, click on retry button to continue.',
  DUPLICATE_CARDS_FOUND:
    'Card generation process failed due to duplicate cards found'
};
export const FIREBASE_ERRORS_TO_BLOCK = [
  'Missing or insufficient permissions.',
  'Failed to get document because the client is offline.'
];
